// Cube shades
export const bigStone = '#12263B'
export const pickledBluewood = '#283C4F'
export const mulledWine = '#504A70'
export const trendyPink = '#785791'
export const melanie = '#E6BCCD'
export const brightSun = '#FCCA46'

export const dew = '#EEFFFF'
export const indigo = '#545dc782'
export const lightGreen = '#84DD63'
export const grannyApple = '#BDEDAB'
export const roseMader = '#DB2B39'
export const pastel = '#F0A8AE'
